import { useAuthStore } from '@/stores/auth'
import UnauthenticatedError from '@/types/Auth/UnauthenticatedError'
import ValidationException from '@/types/Errors/ValidationException'
import type User from '@/types/User'
import { type Store } from 'pinia'

class HttpService {
  private auth: Store<
    'auth',
    {
      user: User
      token: string
    }
  >

  constructor() {
    this.auth = useAuthStore()
  }

  async get(url: string, headers?: object): Promise<Response> {
    let heads = {
      Accept: 'application/json'
    }

    if (headers !== undefined) {
      heads = { ...heads, ...headers }
    }

    if (this.auth.token !== '') {
      heads = { ...heads, ...{ Authorization: 'Bearer ' + this.auth.token } }
    }

    return fetch(url, {
      method: 'GET',
      referrerPolicy: 'no-referrer',
      redirect: 'follow',
      headers: heads
    })
  }

  async post(url: string, body: object, headers?: object): Promise<Response> {
    let heads = {
      'Content-Type': 'application/json',
      Accept: 'application/json'
    }

    if (headers !== undefined) {
      heads = { ...heads, ...headers }
    }

    if (this.auth.token !== '') {
      heads = { ...heads, ...{ Authorization: 'Bearer ' + this.auth.token } }
    }

    return fetch(url, {
      method: 'POST',
      referrerPolicy: 'no-referrer',
      redirect: 'follow',
      headers: heads,
      body: JSON.stringify(body)
    }).then((resp) => {
      if (resp.status === 401) {
        throw new UnauthenticatedError()
      } else if (resp.status === 422) {
        const error = new ValidationException({ response: resp })
        error.hydrate()
        throw error
      }
      return resp
    })
  }

  async delete(url: string, body: object, headers?: object): Promise<Response> {
    let heads = {
      'Content-Type': 'application/json',
      Accept: 'application/json'
    }

    if (headers !== undefined) {
      heads = { ...heads, ...headers }
    }

    if (this.auth.token !== '') {
      heads = { ...heads, ...{ Authorization: 'Bearer ' + this.auth.token } }
    }

    return fetch(url, {
      method: 'DELETE',
      referrerPolicy: 'no-referrer',
      redirect: 'follow',
      headers: heads,
      body: JSON.stringify(body)
    }).then((resp) => {
      if (resp.status === 401) {
        throw new UnauthenticatedError()
      }
      return resp
    })
  }
}

export default HttpService
