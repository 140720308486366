import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../pages/HomeView.vue'
import { useAuthStore } from '@/stores/auth'

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: '/',
      name: 'home',
      component: HomeView
    },
    {
      path: '/login',
      name: 'login',
      component: () => import('../pages/Auth/LoginPage.vue')
    },
    {
      path: '/signup',
      name: 'signup',
      component: () => import('../pages/Auth/SignUp.vue')
    },
    {
      path: '/dashboard',
      name: 'dashboard',
      component: () => import('../pages/Dashboard/HomePage.vue')
    },
    {
      path: '/plays',
      name: 'plays',
      component: () => import('../pages/Dashboard/Plays/PlaysIndex.vue')
    },
    {
      path: '/scenarios',
      name: 'scenarios',
      component: () => import('../pages/Dashboard/Scenarios/ScenariosIndex.vue')
    },
    {
      path: '/scenarios/:name',
      name: 'scenarios-show',
      component: () => import('../pages/Dashboard/Scenarios/ScenariosShow.vue')
    },
    {
      path: '/plays/shell',
      name: 'play-shell',
      component: () => import('../pages/Dashboard/Plays/PlaysShell.vue')
    },
    {
      path: '/account',
      name: 'account-show',
      component: () => import('../pages/Dashboard/Account/AccountShow.vue')
    },
    {
      path: '/account/update-password',
      name: 'update-password',
      component: () => import('../pages/Dashboard/Account/UpdatePassword.vue')
    },
    {
      path: '/account/configure-mfa',
      name: 'configure-mfa',
      component: () => import('../pages/Dashboard/Account/ConfigureMFA.vue')
    },
    {
      path: '/account/remove-mfa',
      name: 'remove-mfa',
      component: () => import('../pages/Dashboard/Account/RemoveMFA.vue')
    },
    {
      path: '/account/create-token',
      name: 'create-token',
      component: () => import('../pages/Dashboard/Account/CreateToken.vue')
    },
    {
      path: '/plays/:id',
      name: 'play-show',
      component: () =>
        import(/* webpackChunkName: "plays-show" */ '../pages/Dashboard/Plays/PlaysShow.vue')
    },
    {
      path: '/:pathMatch(.*)*',
      name: '404',
      component: () => import('../pages/Errors/NotFound.vue')
    }
  ]
})

router.beforeEach(async (to) => {
  // Routes that are allowed to unauthenticated users
  const publicPages = ['/', '/login', '/signup']
  // Routes that are not allowed to authenticated users
  const noAuthPages = ['/', '/login', '/signup']

  // Is true when a page requires authentication
  const authRequired = !publicPages.includes(to.path)
  // Is true when a page cannot be viewed with authentication
  const authDenied = noAuthPages.includes(to.path)

  const auth = useAuthStore()

  if (authRequired && auth.token === '') {
    return { name: 'login' }
  }
  if (authDenied && auth.token !== '') {
    return { name: 'dashboard' }
  }
})

router.onError((error, to) => {
  if (
    error.message.includes('Failed to fetch dynamically imported module') ||
    error.message.includes('Importing a module script failed')
  ) {
    if (!to?.fullPath) {
      window.location.reload()
    } else {
      window.location.assign(to.fullPath)
    }
  }
})

export default router
