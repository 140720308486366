<template>
  <div class="flex flex-col justify-center gap-4">
    <div class="mx-auto w-10/12 sm:w-4/5 md:w-2/3 lg:w-1/2">
      <img
        src="@/assets/run.gif"
        alt="Animation of srep cli running a play"
        class="rounded-lg shadow"
      />
    </div>
  </div>
</template>
