import { usePlayStore } from '@/stores/play'
import { isEmpty } from '@/utils'
import { useRoute } from 'vue-router'

export default function useRunningPlay() {
  const plays = usePlayStore()
  const route = useRoute()
  function hasRunningPlay(): boolean {
    if (route.path === '/plays/shell') {
      return false
    }
    if (plays.play !== null && !isEmpty(plays.play)) {
      return true
    }
    return false
  }

  return {
    hasRunningPlay
  }
}
