import type GetPlaysResponse from '@/types/Plays/GetPlaysResponse'
import HttpService from './HttpService'
import type StartPlayResonse from '@/types/Plays/StartPlayResponse'
import type GetActivePlayResponse from '@/types/Plays/GetActivePlayResponse'
import type FindPlayResponse from '@/types/Plays/FindPlayResponse'
import { usePlayStore, type PlayState } from '@/stores/play'
import { type Store } from 'pinia'
import type CheckPlayResponse from '@/types/Plays/CheckPlayResponse'

class PlaysService {
  private http: HttpService

  private store: Store<'play', PlayState>

  constructor(http: HttpService) {
    this.http = http
    this.store = usePlayStore()
  }

  async get(): Promise<GetPlaysResponse> {
    return this.http.get(import.meta.env.VITE_API_URL + '/plays').then((resp) => {
      return resp.json()
    })
  }

  async getActive(): Promise<GetActivePlayResponse> {
    return this.http.get(import.meta.env.VITE_API_URL + '/plays/active').then((resp) => {
      return resp.json()
    })
  }

  async updateActive() {
    const resp = await this.getActive()
    this.store.play = resp.play
  }

  async find(id: string): Promise<FindPlayResponse> {
    return this.http.get(import.meta.env.VITE_API_URL + '/plays/' + id).then((resp) => {
      return resp.json()
    })
  }

  async start(play: string): Promise<StartPlayResonse> {
    return this.http
      .post(import.meta.env.VITE_API_URL + '/plays', {
        scenario: play
      })
      .then((resp) => {
        return resp.json()
      })
  }

  async check(id: string): Promise<CheckPlayResponse> {
    return this.http
      .post(import.meta.env.VITE_API_URL + '/plays/check', {
        id: id
      })
      .then((resp) => {
        return resp.json()
      })
  }

  async cancel(id: string): Promise<Boolean> {
    return this.http
      .post(import.meta.env.VITE_API_URL + '/plays/cancel', {
        id: id
      })
      .then(() => {
        return true
      })
  }
}

export default PlaysService
