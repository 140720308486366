<template>
  <div
    v-if="hasRunningPlay()"
    class="fixed bottom-0 md:bottom-12 md:right-12 h-24 md:w-64 w-full right-0 md:rounded flex items-center justify-center shadow-lg z-50"
  >
    <router-link
      to="/plays/shell"
      class="h-full w-1/3 bg-primary-500 dark:bg-primary-800 text-white md:rounded-l flex items-center justify-center"
    >
      <v-icon name="co-media-play" scale="2" />
    </router-link>
    <div
      class="w-2/3 h-full bg-white dark:bg-surface-800 md:rounded-r flex items-center justify-center text-center"
    >
      <div>
        <span class="text-lg block">Active play</span>
        <span class="block">{{ plays.play?.scenario }}</span>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { usePlayStore } from '@/stores/play'
import useRunningPlay from '@/composables/runningPlay'
import Socket from '@/services/Socket'
import type SocketEvent from '@/types/SocketEvent'
import type GetActivePlayResponse from '@/types/Plays/GetActivePlayResponse'
import { onMounted, onUnmounted } from 'vue'

const plays = usePlayStore()
const { hasRunningPlay } = useRunningPlay()

const socket = new Socket(import.meta.env.VITE_WS_URL + '/plays/active/stream')
socket.onMessage = (evt: SocketEvent) => {
  const play = JSON.parse(evt.content) as GetActivePlayResponse
  plays.play = play.play
}
socket.onClose = () => {
  console.log('closed')
}

onMounted(async () => {
  socket.connect()
  await socket.waitForConnected()
})
onUnmounted(() => {
  socket.close()
})
</script>
