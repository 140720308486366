import type LoginResponse from '@/types/Auth/LoginResponse'
import type MeResponse from '@/types/Auth/MeResponse'
import UnauthenticatedError from '@/types/Auth/UnauthenticatedError'
import HttpService from './HttpService'
import { useCookies } from 'vue3-cookies'
import type ConfirmPasswordResponse from '@/types/Auth/ConfirmPasswordResponse'
import type UpdatePasswordResponse from '@/types/Auth/UpdatePasswordResponse'
import type ConfigureMfaResponse from '@/types/Auth/ConfigureMfaResponse'
import type RemoveMFAResponse from '@/types/Auth/RemoveMFAResponse'
import type GetTokensResponse from '@/types/Auth/GetTokensResponse'
import type CreateTokenResponse from '@/types/Auth/CreateTokenResponse'
import type SignupResponse from '@/types/Auth/SignupResponse'

const { cookies } = useCookies()

class AuthService {
  private http: HttpService

  constructor(http: HttpService) {
    this.http = http
  }

  async login(username: string, password: string): Promise<LoginResponse> {
    return this.http
      .post(import.meta.env.VITE_API_URL + '/auth/login', {
        email: username,
        password
      })
      .then((resp) => {
        if (resp.status === 401) {
          throw new UnauthenticatedError()
        }
        return resp.json()
      })
  }

  async verifyMfa(authenticationID: string, code: string): Promise<LoginResponse> {
    return this.http
      .post(import.meta.env.VITE_API_URL + '/auth/mfa/verify', {
        authentication_id: authenticationID,
        code: code
      })
      .then((resp) => {
        if (resp.status === 401) {
          throw new UnauthenticatedError()
        }
        return resp.json()
      })
  }

  async configureMfa(provider: string): Promise<ConfigureMfaResponse> {
    return this.http
      .post(import.meta.env.VITE_API_URL + '/auth/mfa/configure', {
        provider
      })
      .then((resp) => {
        if (resp.status === 401) {
          throw new UnauthenticatedError()
        }
        return resp.json()
      })
  }

  async removeMfa(): Promise<RemoveMFAResponse> {
    return this.http.delete(import.meta.env.VITE_API_URL + '/auth/mfa', {}).then((resp) => {
      if (resp.status === 401) {
        throw new UnauthenticatedError()
      }
      return resp.json()
    })
  }

  async me(token: string): Promise<MeResponse> {
    return this.http
      .get(import.meta.env.VITE_API_URL + '/auth/me', {
        Authorization: 'Bearer ' + token
      })
      .then((resp) => {
        if (resp.status === 401) {
          throw new UnauthenticatedError()
        }
        return resp.json()
      })
  }

  async confirmPassword(password: string): Promise<ConfirmPasswordResponse> {
    return this.http
      .post(import.meta.env.VITE_API_URL + '/auth/password/confirm', { password: password })
      .then((resp) => {
        if (resp.status === 401) {
          throw new UnauthenticatedError()
        }
        return resp.json()
      })
  }

  async updatePassword(
    currentPassword: string,
    newPassword: string,
    newPasswordConfirmation: string
  ): Promise<UpdatePasswordResponse> {
    return this.http
      .post(import.meta.env.VITE_API_URL + '/auth/password/update', {
        current_password: currentPassword,
        new_password: newPassword,
        new_password_confirmation: newPasswordConfirmation
      })
      .then((resp) => {
        if (resp.status === 401) {
          throw new UnauthenticatedError()
        }
        return resp.json()
      })
  }

  async getTokens(): Promise<GetTokensResponse> {
    return this.http.get(import.meta.env.VITE_API_URL + '/auth/tokens').then((resp) => {
      if (resp.status === 401) {
        throw new UnauthenticatedError()
      }
      return resp.json()
    })
  }

  async createToken(name: string): Promise<CreateTokenResponse> {
    return this.http
      .post(import.meta.env.VITE_API_URL + '/auth/tokens', {
        name: name
      })
      .then((resp) => {
        if (resp.status === 401) {
          throw new UnauthenticatedError()
        }
        return resp.json()
      })
  }

  async deleteToken(name: string): Promise<boolean> {
    return this.http
      .delete(import.meta.env.VITE_API_URL + '/auth/tokens', {
        name: name
      })
      .then((resp) => {
        if (resp.status === 401) {
          throw new UnauthenticatedError()
        }
        return true
      })
  }

  async logout(): Promise<boolean> {
    return this.http.post(import.meta.env.VITE_API_URL + '/auth/logout', {}).then(() => {
      this.clearSession()
      return true
    })
  }

  async signup(name: string, email: string, password: string): Promise<SignupResponse> {
    return this.http
      .post(import.meta.env.VITE_API_URL + '/auth/register', {
        name,
        email,
        password
      })
      .then((resp) => {
        if (resp.status === 401) {
          throw new UnauthenticatedError()
        }
        return resp.json()
      })
  }

  async deleteAccount(password: string): Promise<void> {
    return this.http
      .delete(import.meta.env.VITE_API_URL + '/auth/account', {
        password
      })
      .then((resp) => {
        if (resp.status === 401) {
          throw new UnauthenticatedError()
        }
        return
      })
  }

  storeSession(token: string, persistent: boolean) {
    let expiry = 0
    if (persistent) {
      expiry = 60 * 60 * 24 * 30
    }
    cookies.set('token', token, expiry, undefined, undefined, true)
  }

  clearSession() {
    cookies.remove('token')
  }

  sessionExists(): boolean {
    return cookies.isKey('token')
  }

  getSession(): string {
    return cookies.get('token')
  }
}

export default AuthService
