import type GetMetdataResponse from '@/types/Metadata/GetMetadataResponse'
import HttpService from './HttpService'
import type FindScenarioResponse from '@/types/Metadata/FindScenarioResponse'

class MetadataService {
  private http: HttpService

  constructor(http: HttpService) {
    this.http = http
  }

  async get(): Promise<GetMetdataResponse> {
    return this.http.get(import.meta.env.VITE_API_URL + '/scenarios').then((resp) => {
      return resp.json()
    })
  }

  async find(name: string, page: number = 1): Promise<FindScenarioResponse> {
    return this.http
      .get(import.meta.env.VITE_API_URL + '/scenarios/' + name + '?page=' + page)
      .then((resp) => {
        return resp.json()
      })
  }
}

export default MetadataService
