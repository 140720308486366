import { defineStore } from 'pinia'
import type User from '@/types/User'
import type UserDetails from '@/types/UserDetails'

export interface AuthState {
  user: User
  token: string
  details: UserDetails
}

export const useAuthStore = defineStore('auth', {
  state: (): AuthState => {
    return {
      user: {} as User,
      token: '',
      details: {} as UserDetails
    }
  },
  getters: {
    mfaEnabled: (state) => {
      if (state.details.mfa_enabled === true) {
        return true
      }
      return false
    }
  },
  actions: {
    setUser(user: User) {
      this.user = user
    },
    setToken(token: string) {
      this.token = token
    },
    setDetails(details: UserDetails) {
      this.details = details
    },
    clear() {
      this.user = {} as User
      this.token = ''
      this.details = {} as UserDetails
    }
  }
})
