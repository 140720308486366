<template>
  <nav class="bg-primary-500 dark:bg-primary-800 text-white font-bold p-6 flex justify-between">
    <div>
      <router-link
        to="/dashboard"
        active-class="text-gulf-stream-200"
        class="hover:text-gulf-stream-200"
        >SREP</router-link
      >
    </div>
    <div>
      <router-link
        active-class="text-gulf-stream-200"
        to="/scenarios"
        class="hover:text-gulf-stream-200"
        >Scenarios</router-link
      >
      |
      <router-link
        active-class="text-gulf-stream-200"
        to="/plays"
        class="hover:text-gulf-stream-200"
        >Plays</router-link
      >
    </div>
    <div>
      <router-link
        active-class="text-gulf-stream-200"
        to="/account"
        class="hover:text-gulf-stream-200"
        >Me</router-link
      >
      |
      <button @click="logout" class="hover:text-gulf-stream-200">Logout</button>
    </div>
  </nav>
</template>

<script lang="ts" setup>
import useToasts from '@/composables/toasts'
import { useAuthStore } from '@/stores/auth'
import { AuthServiceKey } from '@/symbols'
import { injectStrict } from '@/utils'
import { useRouter } from 'vue-router'

const auth = useAuthStore()
const authService = injectStrict(AuthServiceKey)
const toasts = useToasts()
const router = useRouter()
async function logout() {
  try {
    await authService.logout()
    authService.clearSession()
    auth.clear()
    toasts.info('Logged out')
    router.push('/')
  } catch (err) {
    toasts.error('Something went wrong logging you out')
    console.log(err)
  }
}
</script>
