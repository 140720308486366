import './assets/main.css'

import { createApp } from 'vue'
import { createPinia } from 'pinia'

import App from './App.vue'
import * as Sentry from '@sentry/vue'
import router from './router'
import HttpService from './services/HttpService'
import AuthService from './services/AuthService'
import PlaysService from './services/PlaysService'
import MetadataService from './services/MetadataService'
import { AuthServiceKey, MetadataServiceKey, PlaysServiceKey } from './symbols'
import { useAuthStore } from './stores/auth'
import { useCookies } from 'vue3-cookies'
import { OhVueIcon, addIcons } from 'oh-vue-icons'
import {
  BiArrowLeftShort,
  BiArrowRightShort,
  BiCheckCircleFill,
  CoMediaPlay
} from 'oh-vue-icons/icons'
import useAuthFuncs from './composables/authFuncs'
import PrimeVue from 'primevue/config'
import ToastService from 'primevue/toastservice'
import Sara from '@/presets/sara'
const app = createApp(App)

if (import.meta.env.VITE_SENTRY_DSN !== undefined) {
  Sentry.init({
    app: app,
    dsn: import.meta.env.VITE_SENTRY_DSN,
    environment: import.meta.env.VITE_APP_ENV,
    release: import.meta.env.VITE_APP_VERSION,
    integrations: [
      Sentry.browserTracingIntegration({ router }),
      Sentry.replayIntegration({
        maskAllText: false,
        blockAllMedia: false
      })
    ],
    // Performance Monitoring
    tracesSampleRate: import.meta.env.VITE_SENTRY_TRACE_SAMPLE_RATE || 0.01,
    // Session Replay
    replaysSessionSampleRate: import.meta.env.VITE_SENTRY_REPLAY_SAMPLE_RATE || 0,
    replaysOnErrorSampleRate: import.meta.env.VITE_SENTRY_TRACE_SAMPLE_RATE || 0.01
  })
}

app.use(createPinia())
app.use(router)
app.use(PrimeVue, {
  unstyled: true,
  pt: Sara
})
app.use(ToastService)

addIcons(CoMediaPlay)
addIcons(BiCheckCircleFill)
addIcons(BiArrowLeftShort)
addIcons(BiArrowRightShort)
app.component('v-icon', OhVueIcon)

const http = new HttpService()
const auth = new AuthService(http)
const plays = new PlaysService(http)
const metadata = new MetadataService(http)

app.provide(AuthServiceKey, auth)
app.provide(PlaysServiceKey, plays)
app.provide(MetadataServiceKey, metadata)

const authStore = useAuthStore()
const { cookies } = useCookies()
const { updateMe } = useAuthFuncs(auth, plays)

if (authStore.token === '' && cookies.isKey('token')) {
  authStore.token = cookies.get('token')
  authStore.setToken(cookies.get('token'))
  try {
    await updateMe()
  } catch (error) {
    router.push('/')
  }
}

app.mount('#app')
