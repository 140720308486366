<template>
  <div>
    <Toast position="bottom-center" />
    <ActivePlay />
    <AuthNav />
    <div :class="bodyPad()">
      <div :class="classes()" id="body">
        <router-view />
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import Toast from 'primevue/toast'
import ActivePlay from '@/components/ActivePlay.vue'
import AuthNav from '@/components/AuthNav.vue'
import { useRoute } from 'vue-router'

const wide = ['/dashboard']
const shells = ['/plays/shell']
const route = useRoute()
function isWide(): boolean {
  return wide.includes(route.path)
}
function isShell(): boolean {
  return shells.includes(route.path)
}
function classes(): string {
  if (isWide()) {
    return 'mx-auto w-full md:w-3/4 p-8 md:p-4 lg:p-2 lg:w-5/6'
  }
  if (isShell()) {
    return 'mx-auto w-full md:w-3/4 md:p-4 lg:p-2 lg:w-1/2'
  }
  return 'mx-auto w-full md:w-3/4 p-8 md:p-4 lg:p-2 lg:w-1/2'
}
function bodyPad(): string {
  if (isShell()) {
    return 'md:py-4'
  }
  return 'py-4'
}
</script>
