import type AuthService from '@/services/AuthService'
import type PlaysService from '@/services/PlaysService'
import { useAuthStore } from '@/stores/auth'
import { usePlayStore } from '@/stores/play'
import { useCookies } from 'vue3-cookies'
import * as Sentry from '@sentry/vue'

export default function useAuthFuncs(authService: AuthService, playService: PlaysService) {
  const { cookies } = useCookies()

  async function updateMe() {
    const auth = useAuthStore()
    const plays = usePlayStore()

    try {
      const resp = await authService.me(auth.token)
      auth.setUser(resp.user)
      auth.setDetails(resp.details)
      Sentry.setUser({
        id: resp.user.id
      })

      try {
        const active = await playService.getActive()
        plays.play = active.play
      } catch (error) {
        //
      }
    } catch (error) {
      auth.token = ''
      cookies.remove('token')
      throw error
    }
  }

  return {
    updateMe
  }
}
