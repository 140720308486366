import global from './global.js'
import button from './button'
import splitbutton from './splitbutton'
import checkbox from './checkbox'
import inputnumber from './inputnumber'
import inputtext from './inputtext'
import password from './password'
import tooltip from './tooltip'
import tag from './tag'
import paginator from './paginator'
import toast from './toast'
import chip from './chip'
import card from './card'

export default {
  global,
  directives: {
    tooltip
  },
  button,
  splitbutton,
  checkbox,
  inputnumber,
  inputtext,
  password,
  tag,
  paginator,
  toast,
  chip,
  card
}
