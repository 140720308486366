<template>
  <MainLayout v-if="auth.token !== ''" />
  <UnauthenticatedLayout v-else />
</template>

<script lang="ts" setup>
import MainLayout from './layouts/MainLayout.vue'
import UnauthenticatedLayout from './layouts/UnauthenticatedLayout.vue'
import { useAuthStore } from './stores/auth'

const auth = useAuthStore()
</script>
