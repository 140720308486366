import type ValidationError from './ValidationError'

interface Params {
  message?: string
  response?: Response
}

export default class ValidationException extends Error {
  response: Response
  error: ValidationError

  constructor({ message, response }: Params) {
    super()
    this.response = {} as Response
    this.error = {
      message: '',
      errors: {}
    } as ValidationError
    if (message !== undefined) {
      this.error.message = message
    } else if (response !== undefined) {
      this.response = response
    }
  }

  async hydrate() {
    this.error = await this.response.clone().json()
  }

  hasDetails(): boolean {
    console.log(this.error)
    return Object.keys(this.error.errors).length > 0
  }

  msg(): string {
    return this.error.message
  }

  errors(): object {
    return this.error.errors
  }
}
