import { inject, type InjectionKey } from 'vue'

export function injectStrict<T>(key: InjectionKey<T>, fallback?: T) {
  const resolved = inject(key, fallback)
  if (!resolved) {
    throw new Error(`Could not resolve ${key.description}`)
  }
  return resolved
}

export function sleep(time: number) {
  return new Promise((resolve) => setTimeout(resolve, time))
}

// Wait for loading or before displaying pages
// Sets one place to determine loading
export async function wait<T>(fn: Promise<T>, ms?: number): Promise<T> {
  let ts = 500
  if (ms !== undefined) {
    ts = ms
  }
  const now = Date.now()

  const ret = await fn

  const after = Date.now()
  const diff = after - now

  if (diff < ts) {
    await sleep(ts - diff)
  }

  return ret
}

export function isEmpty(obj: Object): boolean {
  if (obj === undefined || obj === null) {
    return false
  }
  return Object.keys(obj).length === 0
}
