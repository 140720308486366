import { useToast } from 'primevue/usetoast'

export default function useToasts() {
  const toast = useToast()
  const timeout = 2500

  function success(title: string, body?: string) {
    toast.add({
      severity: 'success',
      life: timeout,
      summary: title,
      detail: body
    })
  }

  function error(title: string, body?: string) {
    toast.add({
      severity: 'error',
      life: timeout,
      summary: title,
      detail: body
    })
  }

  function info(title: string, body?: string) {
    toast.add({
      severity: 'info',
      life: timeout,
      summary: title,
      detail: body
    })
  }

  return {
    success,
    error,
    info
  }
}
