import { useAuthStore } from '@/stores/auth'
import type SocketEvent from '@/types/SocketEvent'
import type User from '@/types/User'
import { type Store } from 'pinia'

class Shell {
  private auth: Store<
    'auth',
    {
      user: User
      token: string
    }
  >

  private url: string

  private ws: WebSocket

  onMessage: (msg: SocketEvent) => void

  onError: () => void

  onOpen: () => void

  onClose: () => void

  constructor(url: string) {
    this.auth = useAuthStore()
    this.url = url
    this.ws = {} as WebSocket
    this.onMessage = () => {}
    this.onError = () => {}
    this.onOpen = () => {}
    this.onClose = () => {}
  }

  connect() {
    try {
      this.ws = new WebSocket(this.url + '?token=' + this.auth.token)
    } catch (error) {
      console.error(error)
    }
    this.ws.onerror = () => {
      this.onError()
    }
    this.ws.onopen = () => {
      this.onOpen()
    }
    this.ws.onclose = () => {
      this.onClose()
    }
    this.ws.onmessage = (msg: MessageEvent) => {
      const ev = JSON.parse(msg.data) as SocketEvent
      if (ev.type === 'ping') {
        const pong = { type: 'pong' } as SocketEvent
        this.ws.send(JSON.stringify(pong))
        return
      }
      this.onMessage(ev)
    }
  }

  send(msg: string) {
    const evt = {
      type: 'input',
      content: msg
    } as SocketEvent
    this.ws.send(JSON.stringify(evt))
  }

  async resize(rows: number, cols: number) {
    const evt = {
      type: 'resize',
      content: rows + ',' + cols
    } as SocketEvent
    this.ws.send(JSON.stringify(evt))
  }

  async waitForConnected() {
    if (this.ws.readyState !== this.ws.CONNECTING) {
      return
    }

    while (this.ws.readyState === this.ws.CONNECTING) {
      await new Promise((resolve) => setTimeout(resolve, 250))
    }
  }

  isOpen(): boolean {
    return this.ws.readyState == this.ws.OPEN
  }

  close() {
    this.ws.close()
  }
}

export default Shell
