import { defineStore } from 'pinia'
import type Play from '@/types/Plays/Play'

export interface PlayState {
  play: Play
}

export const usePlayStore = defineStore('play', {
  state: (): PlayState => {
    return {
      play: {} as Play
    }
  },
  actions: {
    clear() {
      this.play = {} as Play
    }
  }
})
